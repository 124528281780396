:root {
  --main-font: Verdana, sans-serif;
  --menu-width: 200px;
}

body {
  font-family: var(--main-font);
  color: #333;
  background-color: #f4f4f9;
  margin: 0;
  padding: 20px;
  font-style: italic;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5em;
  font-style: normal;
}

#dm1, #dm2 {
  font-family: var(--main-font);
  text-align: center;
  font-style: italic;
}

form {
  flex-direction: column;
  align-items: center;
  display: flex;
}

label {
  margin-bottom: 10px;
  font-size: 1.15em;
}

select, button {
  max-width: var(--menu-width);
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  font-size: 1.09em;
}

button {
  color: #fff;
  cursor: pointer;
  background-color: #28a745;
}

button:hover {
  background-color: #218838;
}

@media (width >= 768px) {
  h1 {
    font-size: 2em;
  }

  select, button {
    max-width: var(--menu-width);
  }
}

.warning {
  color: red;
  align-items: center;
  margin-top: 10px;
  font-size: 1em;
  display: none;
}

.toast-success {
  color: #fff;
  z-index: 1000;
  background-color: #28a745;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 1em;
  animation: 4s ease-in-out fadeInOut;
  display: none;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
/*# sourceMappingURL=index.f3d8a9c6.css.map */
